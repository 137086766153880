import styled, { css } from 'styled-components';

import Tooltip from '../Tooltip';

interface ContainerProps {
    isFocused: boolean;
    isFilled: boolean;
    isErrored: boolean;
}

export const Container = styled.div<ContainerProps>`
    background: ${props => props.theme.input.container.background};
    border-radius: 10px;
    padding: 16px;
    width: 100%;

    border: 1px solid ${props => props.theme.input.container.border};
    color: ${props => props.theme.input.container.color};

    display: flex;
    align-items: center;

    & + div {
        margin-top: 8px;
    }

    ${props =>
        props.isErrored &&
        css`
            border-color: ${props.theme.input.error.border};
        `}

    ${props =>
        props.isFocused &&
        css`
            color: ${props.theme.input.isFocused.color};
            border-color: ${props.theme.input.isFocused.border};
        `}

  ${props =>
        props.isFilled &&
        css`
            color: ${props.theme.input.isFilled.color};
        `}

  input {
        flex: 1;
        background: transparent;
        border: 0;
        color: ${props => props.theme.input.color};

        &::placeholder {
            color: ${props => props.theme.input.placeholder};
        }
    }

    svg {
        margin-right: 16px;
    }
`;

export const Error = styled(Tooltip)`
    height: 20px;
    margin-left: 16px;
    svg {
        margin: 0;
    }

    span {
        background: ${props => props.theme.input.error.border};
        color: ${props => props.theme.input.error.color};

        &::before {
            border-color: ${props => props.theme.input.error.border} transparent;
        }
    }
`;
