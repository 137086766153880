import React from 'react';
import { SwitchContainer } from './styles';

interface SwitchProps {
    id: string;
    name: string;
    checked?: boolean;
    onChange: (checked: boolean) => void;
    small?: boolean;
    disabled?: boolean;
    textChecked?: string;
    textUnchecked?: string;
    type?: string;
    text?: string;
}

const Switch: React.FC<SwitchProps> = ({
    id,
    name,
    checked,
    onChange,
    small,
    disabled,
    textChecked,
    textUnchecked,
    type,
    text,
}) => {
    return (
        <SwitchContainer>
            <div
                className={`toggle-switch
                    ${small ? ' small-switch' : ''}
                    ${type ? ` ${type}` : ''}
                `}
            >
                <input
                    type="checkbox"
                    name={name}
                    className="toggle-switch-checkbox"
                    id={id}
                    checked={checked}
                    onChange={e => onChange(e.target.checked)}
                    disabled={disabled}
                />
                {id ? (
                    <label className="toggle-switch-label" htmlFor={id}>
                        <span
                            className={
                                disabled
                                    ? 'toggle-switch-inner toggle-switch-disabled'
                                    : 'toggle-switch-inner'
                            }
                            data-yes={textChecked || ''}
                            data-no={textUnchecked || ''}
                        />
                        <span
                            className={
                                disabled
                                    ? 'toggle-switch-switch toggle-switch-disabled'
                                    : 'toggle-switch-switch'
                            }
                        />
                    </label>
                ) : null}
            </div>
            {text ? <span>{text}</span> : null}
        </SwitchContainer>
    );
};

export default Switch;
