import React from 'react';
import { ToastProvider } from './toast';

interface Props {
    children: React.ReactNode;
}

const AppProvider: React.FC<Props> = ({ children }) => (
    <ToastProvider>{children}</ToastProvider>
);

export default AppProvider;
