import Main from 'pages/Main';
import React from 'react';

import hobbiesInterests3 from '../assets/images/pages/hobbiesInterests3.png';
import hobbiesInterests4 from '../assets/images/pages/hobbiesInterests4.png';
import hobbiesInterests2 from '../assets/images/pages/hobbiesInterests2.png';
import hobbiesInterests5 from '../assets/images/pages/hobbiesInterests5.png';
import hobbiesInterests6 from '../assets/images/pages/hobbiesInterests6.png';
import hobbiesInterests7 from '../assets/images/pages/hobbiesInterests7.png';
import hobbiesInterests1 from '../assets/images/pages/hobbiesInterests1.png';

export default function Home() {
    return (
        <Main>
            <div className="hobbies">
                <figure>
                    <div className="image-text">
                        <span>HOBBIES</span>
                        <span>and</span>
                        <span>INTERESTS</span>
                    </div>
                </figure>
                <div className="title">
                    <h2>HOBBIES</h2>
                </div>
                <div className="hobbiesInterestsFlex">
                    <div className="hobbiesInterests">
                        <img src={hobbiesInterests3} alt="Movies" />
                        <h1>Team work</h1>
                        <p>
                            One thing to do with passion is pass knowledge.
                            Currently, I&apos;working some times a week, helping
                            groups of beginners and advanced developers to get
                            the right way to innovation and creativity.
                        </p>
                    </div>
                    <div className="hobbiesInterests-min">
                        <p>Passion</p>
                        <p>Trello</p>
                        <p>Scientific research</p>
                        <p>Projects</p>
                        <p>Hunger of innovation</p>
                    </div>
                </div>
                <div className="hobbiesInterestsFlex">
                    <div className="hobbiesInterests">
                        <img src={hobbiesInterests4} alt="Movies" />
                        <h1>Be tuned</h1>
                        <p>
                            Thats the way! Many of my applications are to manage
                            information day by day.
                            <br />
                            Read and update me constantly makes me feel good,
                            socially and professionally, helping to keep good
                            ideas and well-aimed planning.
                        </p>
                    </div>
                    <div className="hobbiesInterests-min">
                        <p>Feed all</p>
                        <p>Meet the tomorrow</p>
                        <p>Feedly</p>
                        <p>Reading blogs around the world</p>
                        <p>Twitter</p>
                    </div>
                </div>
                <div className="title">
                    <h2>INTERESTS</h2>
                </div>
                <div className="hobbiesInterestsFlex">
                    <div className="hobbiesInterests">
                        <img src={hobbiesInterests2} alt="Movies" />
                        <h1>Movies</h1>
                        <p>
                            I&apos;m really a lover of the seventh art since
                            childhood. Many times, and a critical detail in all
                            aspects.
                            <br />
                            It&apos;s impossible to list all the movies I love
                            and watched, but I can not fail to mention my
                            favorite categories: science fiction, adventure,
                            suspense, some highlights of other genre and of
                            course all from Disney.
                        </p>
                    </div>
                    <div className="hobbiesInterests-min">
                        <p>Matrix</p>
                        <p>Marvel</p>
                        <p>Pirates of the Caribean</p>
                        <p>Lord of the rings</p>
                        <p>Gravity</p>
                        <p>Jurassic Park</p>
                        <p>Inception</p>
                        <p>Terminator</p>
                        <p>Star Wars</p>
                        <p>Disney</p>
                        <p>REC</p>
                        <p>Hobbit</p>
                    </div>
                </div>

                <div className="hobbiesInterestsFlex">
                    <div className="hobbiesInterests">
                        <img src={hobbiesInterests5} alt="Movies" />
                        <h1>Geek</h1>
                        <p>
                            As a good nerd, I&apos;m a consumer of everything
                            that is good in life and many do not know :). <br />
                        </p>
                        <div id="list">
                            <ul>
                                <li>
                                    Lover of strategy games, puzzle and
                                    adventure.
                                </li>
                                <li>Movies and series fanatic</li>
                                <li>
                                    Turning off TV more and more each day ..
                                    Youtube daily (chromecast ♥).
                                </li>
                                <li>Devouring books that stimulate my mind.</li>
                                <li>
                                    Social networks? .. Yes, but ... Hate
                                    Facebook!
                                </li>
                            </ul>
                        </div>
                        <p />
                    </div>
                    <div className="hobbiesInterests-min">
                        <p>Youtube</p>
                        <p>Smartphone</p>
                        <p>Softwares</p>
                        <p>Strategy games</p>
                        <p>Movies</p>
                        <p>Series</p>
                        <p>Code</p>
                        <p>Chaves</p>
                        <p>Chapolin</p>
                        <p>Jovem Nerd</p>
                        <p>MRG</p>
                        <p>Gadgets buyer</p>
                        <p>Retro games</p>
                    </div>
                </div>

                <div className="hobbiesInterestsFlex">
                    <div className="hobbiesInterests">
                        <img src={hobbiesInterests6} alt="Movies" />
                        <h1>Family</h1>
                        <p>
                            Families are the most important part of our lives.
                            The importance of my mom, dad, brother, girlfriend,
                            everybody... can&apos;t be demonstrated with words.
                            <br />
                            Together we meet new places, we face any
                            challenge... our structure.
                        </p>
                    </div>
                    <div className="hobbiesInterests-min">
                        <p>♥</p>
                        <p>.</p>
                        <p>.</p>
                        <p>.</p>
                    </div>
                </div>

                <div className="hobbiesInterestsFlex">
                    <div className="hobbiesInterests">
                        <img src={hobbiesInterests7} alt="Movies" />
                        <h1>Coding</h1>
                        <p>
                            Code for me is not only a job or a way to live,
                            it&apos;s a gratification see my criativity deployed
                            in a web browser or desktop.
                            <br />
                            That&apos;s what I&apos;ll always want to do, an
                            innovative web!
                        </p>
                    </div>
                    <div className="hobbiesInterests-min">
                        <p>Coding with heart</p>
                        <p>Save the web</p>
                        <p>Integrate system</p>
                        <p>Webmaster</p>
                        <p>Projects comming soon...</p>
                    </div>
                </div>

                <div className="hobbiesInterestsFlex">
                    <div className="hobbiesInterests">
                        <img src={hobbiesInterests1} alt="Movies" />
                        <h1>Android</h1>
                        <p>
                            Always followed the growth of android and how it
                            innovate and revolutionize the market (sorry iphone
                            fans).
                            <br />
                            Actually, I take a time of my day to learn and
                            update me about this world.
                        </p>
                    </div>
                    <div className="hobbiesInterests-min">
                        <p>Android rocks!</p>
                        <p>Using 7 version</p>
                        <p>Coding...</p>
                    </div>
                </div>
            </div>
        </Main>
    );
}
