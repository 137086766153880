import { createElement } from 'react';
import { renderToStaticMarkup } from 'react-dom/server';
import { IconType } from 'react-icons/lib';

export const reactSvgComponentToMarkupString = (
    Component: IconType,
    props: { color: string },
): string =>
    `data:image/svg+xml,${encodeURIComponent(
        renderToStaticMarkup(createElement(Component, props)),
    )}`;
