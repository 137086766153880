import Main from 'pages/Main';
import React from 'react';

export default function Home() {
    return (
        <Main>
            <div className="projects">
                <figure>
                    <div className="image-text">
                        <span>PROJECTS</span>
                        <span>and</span>
                        <span>RELEASES</span>
                    </div>
                </figure>
                <div className="title">
                    <h2>PROJECTS and RELEASES</h2>
                </div>
                <div className="projectsReleases">
                    <h1>now</h1>
                    <div>
                        <span>/web/mobile</span>
                        <p>
                            A bunch of projects comming soon this year, but as a
                            magician never reveals his secrets... =D
                        </p>
                    </div>
                </div>

                <div className="projectsReleases">
                    <h1>Evidence manager</h1>
                    <div>
                        <span>/windows/linux</span>
                        <p>
                            <b>Name:</b> It&apos;s kept in private due to
                            licensing for sales, will soon be available.
                        </p>
                        <p>
                            A test tool focused on the management of the stored
                            historical, agility and quality with robust reports
                            fully functional in Windows, recording and listing
                            each click per screen for later review and
                            documentation.
                        </p>
                        <p>
                            Based on flat UI design using forms, many options
                            are constantly updated and inserted to meet any
                            situation, since a common user to an advanced
                            tester.
                        </p>
                    </div>
                </div>

                <div className="projectsReleases">
                    <h1>this</h1>
                    <div>
                        <span>/web</span>
                        <p>
                            This website you&apos;re reading is actually a
                            handjob, I tried using different technologies in
                            different and more agile ways.
                        </p>
                        <p>
                            People always ask me what is the best methodology to
                            use for design and development ... and I just say,{' '}
                            <i>`&quot;Keep it simple! Less is more!`&quot;</i>.
                        </p>
                    </div>
                </div>
                <div className="title">
                    <h2>GITHUB and other hubs</h2>
                </div>
                <div className="projectsReleases">
                    <p className="italic">
                        Actually, all my projects were removed from GitHub since
                        they&apos;re being licensed for comercial use and sale.
                        <br />
                        If you want more details, contact me on any social
                        network or keep this page on your updates.
                        <br />
                        <br />
                        See you soon...
                    </p>
                </div>
            </div>
        </Main>
    );
}
