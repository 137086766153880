import styled from 'styled-components';

export const Header = styled.div`
    display: flex;
    align-items: center;
    flex-shrink: 0;

    h1 {
        flex-grow: 1;
        font-weight: 500;
        font-size: 1.25rem;
    }

    .buttons {
        display: flex;
        align-items: center;
        justify-content: center;
        overflow: hidden;
        position: relative;

        button {
            width: 2.5rem;
            height: 2.5rem;
            color: ${props => props.theme.modal.close.color};
            border: 0 none;
            background: transparent;
            border-radius: 50%;
            transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
            line-height: 2.9rem;
            font-size: 1rem;

            :hover {
                color: ${props => props.theme.modal.close.hover.color};
                border-color: transparent;
                background: ${props =>
                    props.theme.modal.close.hover.background};
            }
        }
    }
`;

export const Content = styled.div`
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
`;

export const Footer = styled.div`
    display: flex;
    justify-content: flex-end;
`;
