import { lighten } from 'polished';

const primary = '#232054';
const secundary = '#F07034';
const tertiary = '#BCBEC0';
const quaternary = '#414042';

export default {
    title: 'light',

    global: {
        primary,
        secundary,
        tertiary,
        quaternary,

        background: '#fff',
        text: '#333',
        highlightText: '#232054',
        subtext: '#949494',
    },

    font: {
        thin: 100,
        light: 300,
        regular: 400,
        medium: 500,
        bold: 700,
        black: 900,
    },

    link: {
        color: '#232054',
    },

    input: {
        color: '#777',
        placeholder: '#b3b3b3',

        container: {
            background: '#fff',
            color: '#777',
            border: '#E7E7E7',
        },

        isFocused: {
            color: secundary,
            border: secundary,
        },

        isFilled: {
            color: secundary,
        },

        error: {
            background: '#c53030',
            color: '#fff',
            border: '#c53030',
        },
    },

    button: {
        default: {
            background: primary,
            backgroundHover: lighten(0.2, primary),
            color: '#fff',
        },

        transparent: {
            background: '#e5e5e5',
            backgroundHover: lighten(0.03, '#e5e5e5'),
            color: '#333',
        },
    },

    switcher: {
        background: '#b7b7b7',
        color: '#fff',

        checked: {
            background: primary,
            color: '#fff',
        },

        circle: {
            background: '#fff',
            icon: '#FFDA2D',
            border: '1px solid rgba(216,216,216,0.4)',
        },
    },

    themeSwitcher: {
        background: '#e5e5e5',

        circle: {
            background: '#fff',
            icon: '#FFDA2D',
            border: '1px solid rgba(216,216,216,0.4)',
        },
    },

    signin: {
        gear: {
            color: '#000',
            opacity: 0.05,
        },
    },

    modal: {
        background: '#fff',
        close: {
            color: 'rgba(0, 0, 0, 0.6)',

            hover: {
                background: 'rgba(15, 15, 15, 0.1)',
                color: 'rgba(0, 0, 0, 0.4)',
            },
        },
    },

    main: {
        header: {
            background: '#fff',
            border: '#E9E9E9',
        },
    },

    sidebar: {
        background: '#2f323a',
    },

    table: {
        background: '#fff',
        color: '#2f323a',

        button: {
            color: '#555555',
        },
    },

    navBar: {
        backgroundItem: '#fff',
        backgroundSubitem: '#fff',
        border: '#ddd',
        hover: 'rgba(54,54,56,0.05)',
    },

    filterRow: {
        border: '#e9e9e9',

        button: {
            background: '#f5f5f5',
            color: '#bcbdbf',
            border: '#d8d8d8',

            hover: {
                background: '#f3f3f3',
                color: '#a2a2a2',
            },
        },
    },
};
