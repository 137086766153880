import Main from 'pages/Main';
import React, { useCallback, useRef, useState } from 'react';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import * as Yup from 'yup';
import { useToast } from 'hooks/toast';
import getValidationsErrors from 'utils/getValidationErrors';
import Input from 'components/Input';
import Textarea from 'components/Textarea';
import Button from 'components/Button';
import { FiMail } from 'react-icons/fi';
import { BsFillPersonFill } from 'react-icons/bs';
import axios from 'axios';

interface SignInFormData {
    email: string;
    senha: string;
}

export default function Home() {
    const { addToast } = useToast();
    const formRef = useRef<FormHandles>(null);
    const [loading, setLoading] = useState(false);
    const API_PATH = 'https://www.daniloriti.com/api/contact/index.php';

    const handleSubmit = useCallback(
        async (data: SignInFormData) => {
            try {
                setLoading(true);
                formRef.current?.setErrors({});
                const schema = Yup.object().shape({
                    email: Yup.string()
                        .trim()
                        .optional()
                        .email('Invalid e-mail!'),
                    name: Yup.string()
                        .trim()
                        .min(3, 'Say my name! Ops... your name, please :)'),
                    message: Yup.string().trim().min(5, 'Say something...'),
                });

                await schema.validate(data, {
                    abortEarly: false,
                });

                const email =
                    formRef.current?.getFieldValue('email').trim() === ''
                        ? 'contact@daniloriti.com'
                        : formRef.current?.getFieldValue('email').trim();

                const dados = {
                    firstName: formRef.current?.getFieldValue('name').trim(),
                    email,
                    message: formRef.current?.getFieldValue('message').trim(),
                    mailSent: false,
                    error: null,
                };
                axios({
                    method: 'post',
                    url: `${API_PATH}`,
                    headers: { 'content-type': 'application/json' },
                    data: dados,
                })
                    .then(() => {
                        addToast({
                            type: 'success',
                            title: 'Thank you!',
                            description:
                                'I will get back to you as soon as possible!',
                        });
                    })
                    .catch(() => {
                        addToast({
                            type: 'error',
                            title: 'Something wrong! :(',
                            description: 'Please, try again later.',
                        });
                    });

                // reset();
                setLoading(false);
            } catch (err) {
                setLoading(false);

                if (err instanceof Yup.ValidationError) {
                    const errors = getValidationsErrors(err);
                    formRef.current?.setErrors(errors);
                }

                addToast({
                    type: 'error',
                    title: 'Invalid information!',
                    description: 'Please review.',
                });
            }
        },
        [addToast],
    );

    return (
        <Main>
            <div className="contact">
                <figure>
                    <div className="image-text">
                        <span>CONTACT</span>
                        <span>and</span>
                        <span>AUDIENCE</span>
                    </div>
                </figure>
                <Form ref={formRef} onSubmit={handleSubmit}>
                    <div className="field">
                        <label>Name:</label>
                        <Input
                            name="name"
                            placeholder="Say my name! Ops... your name, please :)"
                            icon={BsFillPersonFill}
                        />
                    </div>

                    <div className="field">
                        <label>E-mail:</label>
                        <Input
                            name="email"
                            placeholder="E-mail (Optional)"
                            icon={FiMail}
                        />
                    </div>

                    <div className="field">
                        <label>Message:</label>
                        <Textarea name="message" placeholder="Hello world..." />
                    </div>

                    <Button
                        type="submit"
                        disabled={loading}
                        loading={loading}
                        large
                    >
                        Send message
                    </Button>
                </Form>
            </div>
        </Main>
    );
}
