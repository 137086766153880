import React, { useCallback, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import Lamp from 'components/Lamp';
import Menu from 'components/Menu';
import Footer from 'components/Footer';

import { useCookies } from 'react-cookie';
import { useTheme } from 'hooks/theme';
// import ReactGA from 'react-ga';
import background_border_left_top from '../../assets/images/background_border_left_top.png';
import background_middle_right from '../../assets/images/background_middle_right.png';

type MainProps = {
    children: React.ReactNode;
};

const Main = ({ children }: MainProps) => {
    const [cookies, setCookie, removeCookie] = useCookies(['lamp']);
    const location = useLocation();
    const history = useNavigate();

    const { changeTheme } = useTheme();

    const handleTheme = useCallback(
        (type: boolean) => {
            changeTheme(type);
        },
        [changeTheme],
    );

    const getSite = (page: string) => {
        history(`/${page}`);
    };

    useEffect(() => {
        // ReactGA.pageview(location.pathname);

        if (cookies.lamp !== undefined) {
            if (cookies.lamp) {
                document.body.classList.remove('dark');
            } else {
                document.body.classList.add('dark');
            }

            const disallowCookies = localStorage.getItem('cookies');

            if (disallowCookies === 'true') {
                removeCookie('lamp');
            }
        }

        handleTheme(!document.body.classList.contains('dark'));

        if (location.pathname !== '/') {
            const header = document.getElementsByTagName('header');

            if (header != null) {
                header[0].className += ' no-border';
            }
        }
    }, [cookies, removeCookie, location, handleTheme]);

    return (
        <>
            <div className="background">
                <div className="background-container">
                    <Lamp />

                    <img
                        className="background-border-left-top"
                        alt="Background top effect"
                        src={background_border_left_top}
                    />
                    <img
                        className="background-middle-right"
                        alt="Background middle effect"
                        src={background_middle_right}
                    />
                    <span className="background-circle animate-circle animate-circle-in" />
                    <span className="background-square" />
                </div>
            </div>
            <header>
                <div
                    className="logo"
                    onClick={() => {
                        getSite('');
                    }}
                >
                    <div>
                        <div className="letter">D</div>
                        <div className="letter">D</div>
                        <div className="letter">R</div>
                    </div>
                    <div className="subtitle animate-phrase two">
                        Webmaster, IT Analyst and Filantropo
                    </div>
                </div>
                <div className="me-desktop fade-in two" />
                <div className="name">
                    <div>Danilo Diego Riti</div>
                </div>
            </header>
            <main>
                <section className="">{children}</section>
                <Menu />
            </main>
            <Footer />
        </>
    );
};

export default Main;
