import ReactGA from 'react-ga4';

export function google(category: string, action: string, value?: number) {
    if (value) {
        ReactGA.event({
            category,
            action,
            value,
        });
    } else {
        ReactGA.event({
            category,
            action,
        });
    }
}

export function handleClick(
    url: string,
    category: string,
    action: string,
    newWindow = false,
) {
    google(category, action);
    window.open(url, newWindow ? '_blank' : '');
}
