import React from 'react';
import { Routes, Route } from 'react-router-dom';

import Home from '../pages/Home';
import Skills from '../pages/Skills';
import Professional from '../pages/Professional';
import Hobbies from '../pages/Hobbies';
import Projects from '../pages/Projects';
import Contact from '../pages/Contact';

function RoutesDDR() {
    return (
        <Routes>
            <Route path="/" element={<Home />} />

            <Route path="/skills-experiences" element={<Skills />} />
            <Route path="/professional-timeline" element={<Professional />} />
            <Route path="/hobbies-interests" element={<Hobbies />} />
            <Route path="/projects-releases" element={<Projects />} />
            <Route path="/contacts-audience" element={<Contact />} />
        </Routes>
    );
}

export default RoutesDDR;
