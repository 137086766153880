import styled, { css } from 'styled-components';
import { ButtonTypes } from '.';

interface ButtonProps {
    styleType: string;
}

export const Container = styled.button<ButtonProps>`
    height: 56px;
    border-radius: 10px;
    border: 0;
    padding: 0 16px;
    font-weight: 500;
    margin-top: 10px;
    transition: background-color 0.3s;

    ${props =>
        props.styleType === ButtonTypes.default &&
        css`
            background: ${props.theme.button.default.background};
            color: ${props.theme.button.default.color};

            &:hover {
                background: ${props.theme.button.default.backgroundHover};
            }
        `};

    ${props =>
        props.styleType === ButtonTypes.transparent &&
        css`
            background: ${props.theme.button.transparent.background};
            color: ${props.theme.button.transparent.color};

            &:hover {
                background: ${props.theme.button.transparent.backgroundHover};
            }
        `};
`;
