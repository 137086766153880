import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { ThemeTypeProvider } from './hooks/theme';
import GlobalStyle from './styles/global';
import AppProvider from './hooks';
import RoutesDDR from './routes';
import './styles/styles.scss';

function App() {
    return (
        <Router>
            <ThemeTypeProvider>
                <AppProvider>
                    <RoutesDDR />
                </AppProvider>
                <GlobalStyle />
            </ThemeTypeProvider>
        </Router>
    );
}

export default App;
