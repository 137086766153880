import React, {
    useState,
    useEffect,
    useRef,
    useCallback,
    InputHTMLAttributes,
    useImperativeHandle,
    forwardRef,
} from 'react';
import { IconBaseProps } from 'react-icons';
import { FiAlertCircle } from 'react-icons/fi';
import { useField } from '@unform/core';
import Label from '../Label';

import { Container, Error } from './styles';

export interface InputProps extends InputHTMLAttributes<HTMLTextAreaElement> {
    required?: boolean;
    placeholder?: string;
    label?: string;
    icon?: React.ComponentType<IconBaseProps>;
}

interface InputPropsComplete extends InputProps {
    name: string;
}

interface InputRef {
    focus(): void;
}

const InputClean: React.ForwardRefRenderFunction<InputRef, InputProps> = (
    {
        label,
        required,
        placeholder,
        defaultValue,
        onChange,
        icon: Icon,
        ...rest
    },
    ref,
) => {
    const inputRef = useRef<HTMLTextAreaElement>(null);
    const [isFocused, setIsFocused] = useState(false);
    const [isFilled, setIsFilled] = useState(false);
    const [startedType, setStartedType] = useState(true);

    const handleInputBlur = useCallback(() => {
        setIsFocused(false);
        setIsFilled(!!inputRef.current?.value);
    }, []);

    const handleInputFocus = useCallback(() => {
        setIsFocused(true);
    }, []);

    const handleInputChange = useCallback(
        (event: React.ChangeEvent<HTMLTextAreaElement>) => {
            if (startedType) setStartedType(false);

            onChange && onChange(event);
        },
        [startedType, onChange],
    );

    useImperativeHandle(ref, () => ({
        focus() {
            inputRef.current?.focus();
        },
    }));

    return (
        <div>
            {label && <Label required={required}>{label}</Label>}
            <Container
                isFocused={isFocused}
                isFilled={isFilled}
                isErrored={false}
                data-control="input"
            >
                {Icon && <Icon size={20} />}
                <textarea
                    ref={inputRef}
                    placeholder={placeholder}
                    defaultValue={defaultValue}
                    onFocus={handleInputFocus}
                    onBlur={handleInputBlur}
                    onChange={handleInputChange}
                    {...rest}
                />
            </Container>
        </div>
    );
};

const Input: React.ForwardRefRenderFunction<InputRef, InputPropsComplete> = (
    { name, label, required, placeholder, icon: Icon, ...rest },
    ref,
) => {
    const inputRef = useRef<HTMLTextAreaElement>(null);
    const [isFocused, setIsFocused] = useState(false);
    const [isFilled, setIsFilled] = useState(false);
    const { fieldName, defaultValue, error, registerField } = useField(name);
    const [startedType, setStartedType] = useState(true);

    const handleInputBlur = useCallback(() => {
        setIsFocused(false);
        setIsFilled(!!inputRef.current?.value);
    }, []);

    const handleInputFocus = useCallback(() => {
        setIsFocused(true);
    }, []);

    const handleInputChange = useCallback(() => {
        if (startedType) setStartedType(false);
    }, [startedType]);

    useImperativeHandle(ref, () => ({
        focus() {
            inputRef.current?.focus();
        },
    }));

    useEffect(() => {
        registerField({
            name: fieldName,
            ref: inputRef.current,
            path: 'value',
        });
    }, [fieldName, registerField]);

    return (
        <div>
            {label && <Label required={required}>{label}</Label>}
            <Container
                isFocused={isFocused}
                isFilled={isFilled}
                isErrored={!!error && startedType}
                data-control="input"
            >
                {Icon && <Icon size={20} />}
                <textarea
                    ref={inputRef}
                    placeholder={placeholder}
                    defaultValue={defaultValue}
                    onFocus={handleInputFocus}
                    onBlur={handleInputBlur}
                    onChange={handleInputChange}
                    {...rest}
                />
                {error && startedType && (
                    <Error title={error}>
                        <FiAlertCircle size={20} color="#c53030" />
                    </Error>
                )}
            </Container>
        </div>
    );
};

const InputCleanRef = forwardRef(InputClean);

export { InputCleanRef as InputComponent };
export default forwardRef(Input);
