import React from 'react';
import Modal from 'react-modal';
import { IoMdClose } from 'react-icons/io';
import { Header, Content, Footer } from './styles';

export interface ModalComponentProps {
    large?: boolean;
    isOpen: boolean;
    onRequestClose: () => void;
    title?: string;
    content: React.ReactNode;
    footer?: React.ReactNode;
}

const ModalComponent: React.FC<ModalComponentProps> = ({
    isOpen,
    onRequestClose,
    title,
    content,
    footer,
    large,
}) => {
    return (
        <Modal
            isOpen={isOpen}
            onRequestClose={onRequestClose}
            overlayClassName="react-modal-overlay"
            className="react-modal-content"
            style={{
                content: {
                    maxWidth: large ? '85%' : 576,
                },
            }}
            ariaHideApp={false}
        >
            <Header>
                {title && <h1>{title}</h1>}
                <div className="buttons">
                    <button
                        type="button"
                        onClick={onRequestClose}
                        className="react-modal-close"
                    >
                        <IoMdClose />
                    </button>
                </div>
            </Header>
            <Content>{content}</Content>
            {footer && <Footer>{footer}</Footer>}
        </Modal>
    );
};

export default ModalComponent;
