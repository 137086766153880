import React from 'react';
import TypeWriterEffect from 'react-typewriter-effect';

export interface ButtonToggleComponentProps {
    collapse: boolean;
    setCollapse: (collapse: boolean) => void;
}

const ButtonToggleComponent: React.FC<ButtonToggleComponentProps> = ({
    collapse,
    setCollapse,
}) => {
    function handleClick() {
        setCollapse(!collapse);
    }

    return (
        <button className="buttonCollapse" onClick={handleClick} type="button">
            {collapse ? (
                'zip content..'
            ) : (
                <TypeWriterEffect
                    startDelay={100}
                    cursorColor="black"
                    text="continue reading..."
                    multiText={[
                        'continue reading...',
                        'continue reading...',
                        'continue reading...',
                        'continue reading...',
                        'continue reading...',
                        'continue reading...',
                        'continue reading...',
                        'continue reading...',
                        'continue reading...',
                        'continue reading...',
                        'continue reading...',
                        'continue reading...',
                        'continue reading...',
                        'continue reading...',
                        'continue reading...',
                        'continue reading...',
                        'continue reading...',
                        'continue reading...',
                        'continue reading...',
                        'continue reading...',
                        'continue reading...',
                        'continue reading...',
                        'continue reading...',
                        'continue reading...',
                        'continue reading...',
                        'continue reading...',
                        'continue reading...',
                        'continue reading...',
                    ]}
                    multiTextDelay={2000}
                    typeSpeed={200}
                />
            )}
        </button>
    );
};

export default ButtonToggleComponent;
