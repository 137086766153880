import * as React from 'react';

function SvgComponent(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="250"
            height="200"
            version="1.1"
            viewBox="0 0 165.109 121.546"
            {...props}
        >
            <g fillOpacity="1" transform="translate(-14.105 -71.238)">
                <g transform="matrix(.13852 .00108 -.00109 .1394 -16.507 -51.722)">
                    <path
                        fill="#231f20"
                        stroke="none"
                        strokeDasharray="none"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeMiterlimit="4"
                        strokeOpacity="1"
                        strokeWidth="4.378"
                        d="M859.206 1294.144c0 165.204-115.553 294.87-279.11 294.87-163.557 0-334.48-129.666-334.48-294.87s169.503-299.128 333.06-299.128c163.558 0 280.53 133.924 280.53 299.128z"
                        opacity="1"
                    />
                    <path
                        fill="#aeb0af"
                        strokeWidth="2.419"
                        d="M686.74 998.557l-24.178-5.149c-45.778-9.748-106.992-9.308-158.3 1.139-74.949 15.258-151.856 59.424-199.122 114.342-89.833 104.375-98.473 241.65-22.156 352.046 48.71 70.462 125.585 119.064 221.121 139.791 20.848 4.523 31.813 5.271 76.347 5.235 39.15-.036 56.747-1.047 71.188-4.087 10.55-2.22 19.46-3.777 19.803-3.463 27.336-12.43 10.714-31.464-19.047-18.56-.456.455-10.571 1.942-22.478 3.306-86.243 9.88-180.119-16.878-246.467-70.252-46.163-37.137-78.151-85.561-93.858-142.073-4.35-15.652-8.022-46.545-8.006-59.658-.132-16.784 3.624-43.671 9.38-65.158 22.934-85.609 68.526-152.983 158.706-188.58 22.141-8.74 42.358-15.653 42.358-15.653s-30.18 2.175-50.578 8.926c-41.992 13.799-67.105 30.412-101.87 63.788-33.079 36.454-50.59 58.565-71.509 109.58-8.5 24.696-8.944 17.621-.562-8.947 30.99-98.224 118.778-173.558 230.712-197.988 33.858-7.39 76.075-10.396 103.723-7.389 27.383 2.98 58.324 9.878 58.324 13.006 37.247 17.42 57.885 8.012 26.469-24.202z"
                    />
                    <path
                        fill="#f16f35"
                        strokeWidth="2.54"
                        d="M414.055 1448.382c-28.738-3.848-52-20.815-65.155-47.52l-4.26-8.651c-2.187-4.69-4.529-15.972-4.254-25.895l-.677-71.013c-.748-78.49-.584-71.954 4.695-78.665 6.946-8.83 22.063-9.61 29.395-1.516 4.474 4.938 4.934 11.217 6.108 83.373l1.007 61.844c.562 10.151 1.12 17.484 7.25 26.74 19.117 28.866 63.536 27.304 80.006-2.814 3.87-7.076 4.605-18.219 5.712-86.452l1.155-71.234c1.178-17.47 26.09-19.414 34.53-11.59 5.072 5.07 5.188 7.39 4.445 88.26l-.552 60.13c-.786 16.68-3.732 31.445-7.323 37.406-16.51 33.618-53.538 52.758-92.082 47.597z"
                    />
                    <path
                        fill="#221f54"
                        stroke="none"
                        strokeDasharray="none"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeMiterlimit="4"
                        strokeOpacity="1"
                        strokeWidth="6.254"
                        d="M1419.746 1310.2a423.084 427.344 0 01-423.084 427.343A423.084 427.344 0 01573.577 1310.2a423.084 427.344 0 01423.085-427.344 423.084 427.344 0 01423.084 427.344z"
                        opacity="1"
                    />
                    <path
                        fill="#231f20"
                        stroke="none"
                        strokeDasharray="none"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeMiterlimit="4"
                        strokeOpacity="1"
                        strokeWidth="6.127"
                        d="M989.564 875.758a430.183 430.183 0 10-.001 860.366 430.183 430.183 0 000-860.366zm-2.009 60.138c100.913 0 200.003 30.24 271.359 101.597a380.492 380.492 0 01111.444 269.049c-.001 100.912-41.81 195.671-113.166 267.027a380.492 380.492 0 01-649.54-269.047c0-210.14 169.763-368.626 379.903-368.626z"
                        opacity="1"
                    />
                    <path
                        fill="#aeb0af"
                        strokeWidth="2.396"
                        d="M658.519 1026.083l-17.672 23.737c-39.873 53.577-66.759 115.593-79.45 183.258-7.026 37.47-6.444 122.037 1.096 159.348 11.843 58.6 39.305 123.11 71.493 167.936 8.582 11.952 15.237 22.095 14.789 22.539 58.673 76.622 191.233 163.308 342.185 163.308 0 0 173.018 6.678 297.46-121.522 145.181-149.566 134.713-314.93 134.713-314.93s4.276-229.008-188.06-358.36c-180.272-121.24-359.252-62.911-359.252-62.911-111.618 25.437-191.633 112.123-217.302 137.597zm311.844-123.75c25.61-.193 51.127 1.72 71.666 5.77 85.334 16.828 149.09 51.305 211.638 114.447 29.647 29.927 50.52 58.98 68.624 95.51 16.165 32.617 26.444 61.965 34.207 97.646 4.617 21.224 11.65 51.902 11.324 79.192 1.493 40.124-8.513 77.005-22.856 120.943-32.423 99.324-105.62 184.74-193.69 226.01-39.275 18.405-118.137 37.983-167.027 40.59-14.557.777-24.08 1.356-24.08 1.356s10.535 2.816 29.597 3.077c46.004.63 112.837-10.397 152.72-25.198 115.774-42.962 203.34-138.984 234.028-256.628 5.843-22.396 12.95-76.123 13.081-98.885.036-5.93 1.136-26.229 1.136-26.229s3.213 14.212 3.404 27.335c.292 20.154-2.36 71.856-6.926 93.291-17.113 80.312-53.207 148.083-107.956 202.69-64.485 64.318-148.54 104.166-237.257 112.476-75.81 7.102-145.479-6.214-213.351-40.777-35.345-18-64.258-38.713-95.455-68.376-72.93-69.345-113.512-159.406-119.165-264.467-3.585-66.64 13.455-142.202 45.383-201.281 41.805-77.355 114.944-145.143 183.82-170.374 46.904-17.182 123.314-33.014 162.076-33.58 16.468-.24 17.644-.525 9.276-2.256-34.134-7.06-123.04 3.552-172.76 20.625-124.954 42.908-220.81 152.743-245.387 281.175-2.423 12.66-5.835 39.735-7.586 60.163l-3.188 37.141-.725-37.141c-.799-40.912 1.613-63.381 10.666-99.424 17.648-70.261 50.992-128.48 103.692-181.044 57.006-56.86 119.314-90.37 198.829-106.936 20.923-4.358 46.63-6.651 72.242-6.842z"
                        opacity="1"
                    />
                    <path
                        fill="#fffefc"
                        strokeWidth="2.432"
                        d="M760.481 1436.504l-5.741-4.647-.697-118.778c-.383-65.327-.083-121.267.67-124.31.752-3.045 3.528-7.696 6.169-10.337 4.453-4.452 8.248-4.802 52.136-4.802 41.753 0 48.902.587 60.65 4.982 16.771 6.276 35.974 24.009 42.864 39.585 10.153 22.953 8.57 52.625-3.778 70.821l-5.648 8.324 5.29 3.473c28.604 18.777 41.988 55.885 31.88 88.387-3.512 11.29-7.496 17.328-19.16 29.037-21.915 22-25.853 22.913-98.795 22.913-55.542 0-60.533-.352-65.84-4.648zm131.916-39.785c22.563-15.073 21.508-48.325-1.996-62.939-8.754-5.442-11.275-5.717-52.442-5.717h-43.246l-.53 37.09-.531 37.09h45.24c42.985 0 45.653-.277 53.505-5.522zm-26.976-114.256c13.448-9.152 18.216-17.587 18.216-32.224 0-14.062-4.32-22.183-16.735-31.467-5.862-4.382-10.896-5.112-39.978-5.795l-33.272-.781v75.739h31.865c29.04 0 32.577-.485 39.904-5.472z"
                    />
                    <path
                        fill="#fffefc"
                        strokeWidth="2.432"
                        d="M1013.524 1431.209c-16.541-7.667-30.028-20.383-38.515-36.314-6.467-12.14-6.48-12.268-7.238-75.075-.752-62.29-.706-62.972 4.68-68.704 7.399-7.875 19.833-7.795 27.807.18l5.97 5.97.036 56.654c.036 54.142.28 57.076 5.472 66.13 7.638 13.325 19.048 19.012 35.942 17.915 16.179-1.05 24.66-6.51 31.47-20.253 4.478-9.037 4.9-14.649 4.9-65.167v-55.28l5.97-5.969c8.368-8.369 21.132-8.168 27.902.438 4.779 6.076 5.04 9.415 5.04 64.358 0 52.311-.48 59.235-4.945 71.166-6.72 17.96-21.188 33.702-39.29 42.747-20.174 10.082-45.06 10.541-65.205 1.204z"
                    />
                    <path
                        fill="#fffefc"
                        strokeWidth="2.432"
                        d="M1189.222 1435.102c-12.915-4.04-27.941-14.841-35.024-25.177-7.638-11.147-7.243-21.717 1.08-28.875 9.867-8.487 17.03-7.274 30.29 5.127 10.866 10.164 27.47 17.084 35.336 14.725 1.508-.452 5.73-1.476 9.384-2.275 4.08-.893 8.118-4.305 10.462-8.837 7.566-14.631-.423-22.856-27.077-27.879-22.936-4.322-30.528-7.111-43.9-16.13-13.472-9.087-21.126-24.917-20.952-43.332.52-55.306 73.79-77.688 120.387-36.776 20.209 17.744 16.06 44.017-6.952 44.017-4.899 0-9.684-3.213-17.792-11.946-9.251-9.964-12.977-12.224-22.46-13.625-21.854-3.227-38.585 8.567-34.611 24.398 2.028 8.083 6.54 10.238 28.065 13.406 34.03 5.009 55.604 19.84 64.398 44.273 8.94 24.836-7.097 57.55-33.637 68.62-11.07 4.617-42.642 4.775-56.997.285z"
                    />
                </g>
                <circle
                    cx="162.483"
                    cy="149.022"
                    r="1.016"
                    fill="#5a5a59"
                    fillRule="nonzero"
                    stroke="none"
                    strokeDasharray="none"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit="4"
                    strokeOpacity="1"
                    strokeWidth="0.053"
                    opacity="1"
                />
            </g>
        </svg>
    );
}

export default SvgComponent;
