import React, { useCallback, useState } from 'react';
import {
    AiOutlineFacebook,
    AiOutlineLinkedin,
    AiOutlineInstagram,
} from 'react-icons/ai';

import { BiCookie } from 'react-icons/bi';
import { useCookies } from 'react-cookie';
import { google, handleClick } from 'utils/google';
import insighs from '../assets/insights.json';
import backgroundFooterArt from '../assets/images/backgroundFooterArt.png';
import mimimi from '../assets/images/mimimi.jpg';
import Modal from './Modal';
import Switch from './Switch';
import usePeristedState from '../utils/usePersistedState';

const Footer: React.FC = () => {
    const [isCookiesModalOpen, setCookiesModalOpen] = useState(false);
    const [cookies, setCookie, removeCookie] = useCookies(['lamp']);
    const [disallowCookiesConsent, setDisallowCookiesConsent] =
        usePeristedState('cookies', false);

    function getDateBase() {
        const now = new Date();
        const onejan = new Date(now.getFullYear(), 0, 1);

        return {
            week: Math.ceil(
                ((now.getTime() - onejan.getTime()) / 86400000 +
                    onejan.getDay() +
                    1) /
                    7,
            ),
            year: now.getFullYear(),
        };
    }

    const objDate = getDateBase();
    const insighOfTheWeek = insighs.find(item => {
        return item.week === objDate.week.toString();
    });

    const handlCookiesConsent = useCallback(
        (type: boolean) => {
            setDisallowCookiesConsent(type);

            if (type) {
                removeCookie('lamp');
            }
        },
        [setDisallowCookiesConsent, removeCookie],
    );

    const handleCookiesModal = useCallback(
        (state: boolean) => {
            setCookiesModalOpen(state);

            if (state) {
                google('Internal', 'Cookie');
            }
        },
        [setCookiesModalOpen],
    );

    return (
        <>
            <footer>
                <div className="art">
                    <img
                        alt="Background footer art"
                        src={backgroundFooterArt}
                    />
                </div>
                <div className="container">
                    <div className="wrapper">
                        <div id="insights" className="insights">
                            <div className="title">Insight of the week</div>
                            <div className="phrase">
                                {insighOfTheWeek?.phrase}
                            </div>
                            <hr />
                            <div className="author">
                                {insighOfTheWeek?.author}
                            </div>
                        </div>
                        <div className="social">
                            <button
                                type="button"
                                // rel="noreferrer"
                                className="instagram"
                                onClick={() =>
                                    handleClick(
                                        'https://instagram.com/daniloriti',
                                        'Social',
                                        'Instagram',
                                        true,
                                    )
                                }
                            >
                                <AiOutlineInstagram />
                            </button>
                            <button
                                // rel="noreferrer"
                                type="button"
                                className="facebook"
                                onClick={() =>
                                    handleClick(
                                        'https://www.facebook.com/daniloriti',
                                        'Social',
                                        'Facebook',
                                        true,
                                    )
                                }
                            >
                                <AiOutlineFacebook />
                            </button>
                            <button
                                // rel="noreferrer"
                                type="button"
                                onClick={() =>
                                    handleClick(
                                        'https://linkedin.com/in/daniloriti',
                                        'Social',
                                        'Linkedin',
                                        true,
                                    )
                                }
                                className="linkedin"
                            >
                                <AiOutlineLinkedin />
                            </button>
                        </div>
                    </div>
                    <div className="notepad">
                        <div>
                            share love with open source initiatives like{' '}
                            <button
                                className="roll-link"
                                type="button"
                                onClick={() =>
                                    handleClick(
                                        'https://notepad-plus-plus.org',
                                        'Internal',
                                        'Notepad',
                                        true,
                                    )
                                }
                            >
                                <span data-title="Notepad++">Notepad++</span>
                            </button>
                            .
                        </div>
                        <button
                            type="button"
                            className="cookie"
                            onClick={() => {
                                handleCookiesModal(true);
                            }}
                        >
                            <BiCookie />
                            cookies?
                        </button>
                    </div>
                    <div className="credits">
                        <span>
                            Design and Development by Danilo Diego Riti.
                        </span>
                        <span>Copyright &#169; All Rights Reserved.</span>
                        <span>2013 - {objDate.year}</span>
                    </div>
                </div>
            </footer>

            <Modal
                isOpen={isCookiesModalOpen}
                onRequestClose={() => {
                    setCookiesModalOpen(false);
                }}
                title="Cookies consent notice"
                content={
                    <div className="cookies">
                        <p>
                            I just use cookies to store your favorite theme
                            settings and just if you change it.
                        </p>
                        <p>
                            If you can&apos;t accept it, just click the button
                            bellow:
                        </p>
                        <button type="button">
                            <img src={mimimi} alt="Disable cookies meme" />
                            <Switch
                                id="disableCookie"
                                name="disableCookie"
                                checked={disallowCookiesConsent}
                                onChange={e => handlCookiesConsent(e)}
                                text="I hate cookies!"
                            />
                        </button>
                    </div>
                }
                footer={
                    <button
                        type="button"
                        onClick={() => {
                            setCookiesModalOpen(false);
                        }}
                    >
                        Close
                    </button>
                }
            />
        </>
    );
};

export default Footer;
