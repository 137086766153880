import * as React from 'react';

function SvgComponent(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg
            width="43.98mm"
            height={156.631}
            viewBox="0 0 43.98 41.442"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <g transform="translate(80.198 -143.23)">
                <ellipse
                    cx={-61.037}
                    cy={159.77}
                    rx={10.558}
                    ry={10.496}
                    fill="#0e8ecb"
                />
                <ellipse
                    cx={-61.037}
                    cy={159.77}
                    rx={4.981}
                    ry={4.952}
                    fill="#f5b142"
                    stroke="#f5b142"
                    strokeLinejoin="round"
                    strokeWidth={0.38}
                />
                <g fill="none" stroke="#0d2456" strokeLinejoin="round">
                    <ellipse cx={-61.037} cy={159.77} rx={16.135} ry={16.04} />
                    <ellipse
                        cx={-61.037}
                        cy={159.77}
                        rx={13.416}
                        ry={13.337}
                        strokeWidth={0.831}
                    />
                    <ellipse
                        cx={-61.037}
                        cy={159.77}
                        rx={10.558}
                        ry={10.496}
                        strokeWidth={0.654}
                    />
                    <ellipse
                        cx={-61.037}
                        cy={159.77}
                        rx={7.885}
                        ry={7.839}
                        strokeWidth={0.489}
                    />
                    <ellipse
                        cx={-61.037}
                        cy={159.77}
                        rx={4.981}
                        ry={4.952}
                        strokeWidth={0.38}
                    />
                </g>
                <path
                    d="M-62.005 159.8l2.008-1.536-.07 3.497z"
                    fill="#20a77e"
                />
                <path
                    d="M-62.052 159.96l2.126-1.795-.047 3.827z"
                    fill="none"
                    stroke="#0d2456"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={0.6}
                />
                <path
                    d="M-43.461 159.98s2.126-2.457 2.22-2.457l4.442.024-1.37 2.551 1.44 2.268-4.511-.024z"
                    fill="#20a77e"
                />
                <g stroke="#0d2456">
                    <path
                        d="M-59.974 159.91l16.442.095 2.22-2.434h4.536l-1.44 2.386 1.464 2.41h-4.583l-2.197-2.339"
                        fill="none"
                        strokeWidth={0.6}
                    />
                    <path
                        d="M-54.969 175.15l5.613 8.954h3.608l-6.615-10.558zM-66.254 175.15l-5.613 8.954h-3.608l6.615-10.558z"
                        fill="#f5b142"
                        strokeWidth={0.665}
                    />
                    <path
                        d="M-79.691 184.17h38.554"
                        fill="none"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                </g>
            </g>
        </svg>
    );
}

export default SvgComponent;
