import React, { useState } from 'react';

import ButtonToggleHook from 'components/ButtonToggleHook';

import Main from 'pages/Main';
import me from '../assets/images/me3.png';
import back_tech_text from '../assets/images/back_tech_text.jpg';
import ArticleFeather from '../assets/images/articleFeather';
import glog from '../assets/images/glog.gif';

export default function Home() {
    const [collapse, setCollapse] = useState(false);

    return (
        <Main>
            <div className="about-description">
                <div className="who-i-am">
                    who<span>.Iam</span>
                </div>
                <img src={me} alt="me" />
                <p>
                    ... focused on new technologies and startups, developing
                    innovations in areas of security and optimization to improve
                    systems quality.
                    <br />
                    <br />
                    Architect and designer of multiplatform and enterprise
                    systems, with initiatives in scientific and technological
                    researchs, cloud computing and web based.
                    <br />
                    <br />
                    In spare time, an admirer of the seventh art, read and
                    officially a{' '}
                    <a
                        href="http://en.wikipedia.org/wiki/Geek"
                        className="roll-link"
                        rel="nofollow noreferrer"
                        target="_blank"
                    >
                        <span data-title="geek">geek</span>
                    </a>
                    .
                </p>
                <div className="insight">
                    ... challenges are the greatest energy!
                </div>
            </div>
            <div className="title">
                <h2>WRITING...</h2>
            </div>
            <div className="article">
                <figure>
                    <img src={back_tech_text} alt="article" />
                </figure>
                <div className="content">
                    <ArticleFeather className="article-thumb" />
                    <span className="article-firstLetter">A</span>
                    <p>
                        large numbers of new browsers born every year, whether
                        for mobile or desktop platforms, companies waging real
                        wars including new services and tools within their
                        products that ultimately has only one purpose, which is
                        to be an intermediary between the user and the
                        information.
                    </p>
                    <p>
                        It is usual in this sector, divide the evolutionary
                        criteria in 3: web 1.0, web 2.0 and the still unknown,
                        however, not distant, web 3.0. We are currently in the
                        middle of a migration process between version 2.0 and
                        3.0, but there is a controversy as the current version
                        has given us so many changes in terms of not only
                        design, as well native interpolation of objects and rich
                        detailed animations, but arguably the main outstanding
                        feature, is the interaction of information’s with user.
                    </p>
                    <div
                        id="collapse1"
                        className={collapse ? 'panel-collapse' : 'panel-close'}
                    >
                        <p>
                            All these factors were driven by mobile age and its
                            exponential growth; fact that made the development
                            of web 2.0 take an alternate dimension, not
                            stagnating growth, but adapting an existing standard
                            established by two areas of society: mobile
                            companies X systems / services.
                        </p>
                        <p>
                            For many people, the standard for navigation is what
                            we actually have, systems like Facebook, Gmail,
                            Twitter, based on powerful APIs constantly evolving
                            and non-relational database, which have always a
                            similar architecture X user information. A similar
                            scenario was seen by those who have followed all
                            browsers growth, where the first jump of web 1.0 and
                            video streaming were extremely complex, a user only
                            served to generate a constant identification in a
                            database. Are two times not so distant, in the past
                            a company generated the content customer-focused,
                            and currently, the content is generated by clients
                            and managed by the companies.
                        </p>
                        <h3>But where is the browser in this story?</h3>
                        <p>
                            What I want to show is that as more evolved a
                            navigation system is, it is just a reader, or a
                            communicator between point A and B, without one of
                            these pillars there is no bridge.
                        </p>
                        <p>
                            The similarity pursuit between the real and the
                            virtual, will make our internet standards change, I
                            can say that web 3.0 will never exist. Technologies
                            like “
                            <a
                                href="https://en.wikipedia.org/wiki/Oculus_VR"
                                className="roll-link"
                                rel="nofollow noreferrer"
                                target="_blank"
                            >
                                <span data-title="Oculus VR">Oculus VR</span>
                            </a>
                            ”, which provide total immersion in the virtual
                            world, and probably will be the next consoles
                            generation. All these stuff will have great emphasis
                            on websites, which soon, will become as an address
                            of a large map like a live Street View, walking
                            through the rooms of a portal, and not by static
                            information pages; to give a small example, imagine
                            dear Wikipedia designed as a large library, with a
                            search like the guns call that Neo does in Matrix
                            movie.
                        </p>
                        <p>
                            <img src={glog} alt="Matrix - Lots of guns" />
                            I&apos;m really expecting that the third episode of
                            &quot;web trilogy&quot; be something bigger than an
                            update with new ready methods or deprecated
                            commands. It will be so amazing to participate and
                            help this wonderful future become the internet of
                            things and the internet of virtual reality.
                        </p>
                    </div>
                    ~$ /home/{' '}
                    <ButtonToggleHook
                        collapse={collapse}
                        setCollapse={setCollapse}
                    />
                </div>
            </div>
        </Main>
    );
}
