import * as React from 'react';

function SvgComponent(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg
            width={51.7}
            height={74.494}
            viewBox="0 0 13.679 19.71"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                d="M4.591 19.52c-.25-.277-.238-1.732.017-1.963.165-.15.619-.167 4.404-.167 3.656 0 4.25.021 4.443.157.2.14.224.243.224.99 0 .68-.034.865-.184 1-.165.15-.622.167-4.46.167-4.016 0-4.288-.011-4.444-.184z"
                fill="#e7556e"
            />
            <path
                d="M.264 18.8c-.254-.2-.265-.25-.264-1.182.001-.535.035-1.023.076-1.084.199-.298.56-.342 2.81-.342h2.298v.402c0 .323-.037.403-.183.403-.271 0-.756.275-.875.497-.057.106-.103.49-.103.854v.66H2.276c-1.615 0-1.767-.015-2.012-.208z"
                fill="#1790ae"
            />
            <path
                d="M5.564 16.5c.032-.159.31-.622.618-1.031.512-.68.959-1.59.959-1.954 0-.124.295-.147 1.864-.147 1.687 0 1.87.017 1.92.176.246.762.554 1.37.973 1.925.517.683.714 1.074.62 1.227-.033.053-1.537.092-3.535.092H5.506z"
                fill="#e7556e"
            />
            <path
                d="M1.434 15.09c.03-.217.166-.546.304-.73.554-.738.989-1.838 1.323-3.351.207-.937.459-2.786.459-3.373v-.472l.528-.063c.29-.035 1.115-.064 1.832-.064l1.304-.001-.47.478c-.832.847-1.174 2.119-.85 3.162.125.4.126.48.008.525-.104.04-.14.281-.14.962 0 .992-.03.95.68.913.235-.013.427.018.427.069 0 .31-.357 1.189-.7 1.72l-.402.62H1.381z"
                fill="#1790ae"
            />
            <path
                d="M8.474 12.69l-2.339-.027v-.551c0-.54.007-.552.287-.552h.286l-.252-.51c-.352-.71-.363-1.755-.026-2.424.293-.583.822-1.137 1.359-1.423.326-.174.58-.217 1.263-.217.965 0 1.436.182 2.046.792.842.842 1.077 2.208.56 3.254l-.261.528h.58l-.068.528c-.037.29-.072.534-.079.54-.055.06-1.41.084-3.356.062z"
                fill="#e7556e"
            />
            <path
                d="M2.111 5.63v-.704h.402c.222 0 .403-.013.403-.03 0-.016-.181-.472-.403-1.014-.454-1.113-.477-1.271-.218-1.506.245-.222.345-.21.781.085.306.208.49.252 1.06.252.705 0 .82-.07.524-.315-.092-.076-.253-.33-.357-.564-.156-.354-.17-.494-.082-.823.183-.68.98-1.144 1.666-.972.142.036.417.21.61.389.597.546.562 1.346-.09 2.059l-.207.226.546-.004c.75-.006 1.353-.135 1.55-.333.199-.198.177-.196.512-.044.254.116.265.152.217.681-.028.31-.187.86-.358 1.236l-.307.677h.692v1.409H2.11z"
                fill="#1790ae"
            />
        </svg>
    );
}

export default SvgComponent;
