/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';

const Menu: React.FC = () => {
    const [menuMobile, setMenuMobile] = useState(false);
    const history = useNavigate();

    const loaderSymbols = ['0', '1', '2', '3', '4', '5', '6', '7'];
    const loaderRate = 300;
    let loaderIndex = 0;
    let threadLoader: NodeJS.Timeout;

    const loaderRef = useRef<HTMLSpanElement | null>(null);

    const getSite = (page: string) => {
        history(`/${page}`);
    };

    function loader() {
        clearTimeout(threadLoader);

        const elemento = loaderRef.current;

        if (elemento) {
            elemento.innerHTML = loaderSymbols[loaderIndex];
            loaderIndex =
                loaderIndex < loaderSymbols.length - 1 ? loaderIndex + 1 : 0;
            threadLoader = setTimeout(loader, loaderRate);
        }
    }

    useEffect(() => {
        loader();

        return () => {
            clearTimeout(threadLoader);
        };
    }, [loader]);

    function btnMobile() {
        setMenuMobile(!menuMobile);
    }

    const [dimensions, setDimensions] = useState({
        // height: window.innerHeight,
        width: window.innerWidth,
    });
    useEffect(() => {
        const debouncedHandleResize = function handleResize() {
            setDimensions({
                // height: window.innerHeight,
                width: window.innerWidth,
            });
        };

        window.addEventListener('resize', debouncedHandleResize);

        return () => {
            window.removeEventListener('resize', debouncedHandleResize);
            // console.log(dimensions.width);
        };
    });

    return (
        <nav>
            <button
                id="btnMobile"
                onClick={btnMobile}
                type="button"
                aria-label="Open menu"
            />
            {/* <ul id="menu" className={`ca-menu${menuMobile && dimensions.width && dimensions.width <= 1000 ? ' ca-menu-mobile animate-menu' : ''}`}> */}
            <ul
                id="menu"
                className={` ${
                    menuMobile && dimensions.width && dimensions.width <= 1000
                        ? ' open'
                        : ''
                }`}
            >
                <li>
                    <a onClick={() => getSite('')}>
                        <span className="ca-icon" id="loader" ref={loaderRef}>
                            0
                        </span>
                        <div className="ca-content">
                            <div className="ca-main">Home</div>
                            <span className="ca-sub">and Overview</span>
                        </div>
                    </a>
                </li>
                <li>
                    <a onClick={() => getSite('skills-experiences')}>
                        <span className="ca-icon">e</span>
                        <div className="ca-content">
                            <div className="ca-main">Skills</div>
                            <span className="ca-sub">and Experiences</span>
                        </div>
                    </a>
                </li>
                <li>
                    <a onClick={() => getSite('professional-timeline')}>
                        <span className="ca-icon">c</span>
                        <div className="ca-content">
                            <div className="ca-main">Professional</div>
                            <span className="ca-sub">and Timeline</span>
                        </div>
                    </a>
                </li>
                <li>
                    <a onClick={() => getSite('hobbies-interests')}>
                        <span className="ca-icon">.</span>
                        <div className="ca-content">
                            <div className="ca-main">Hobbies</div>
                            <span className="ca-sub">and Interests</span>
                        </div>
                    </a>
                </li>
                <li>
                    <a onClick={() => getSite('projects-releases')}>
                        <span className="ca-icon">/</span>
                        <div className="ca-content">
                            <div className="ca-main">Projects</div>
                            <span className="ca-sub">and Releases</span>
                        </div>
                    </a>
                </li>
                <li>
                    <a onClick={() => getSite('contacts-audience')}>
                        <span className="ca-icon">8</span>
                        <div className="ca-content">
                            <div className="ca-main">Contacts</div>
                            <span className="ca-sub">and Audience</span>
                        </div>
                    </a>
                </li>
            </ul>
        </nav>
    );
};

export default Menu;
