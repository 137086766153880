import Main from 'pages/Main';
import React from 'react';
import professionalTimeline1 from '../assets/images/professionalTimeline1.jpg';
import professionalTimeline2 from '../assets/images/professionalTimeline2.jpg';
import professionalTimeline3 from '../assets/images/professionalTimeline3.jpg';
import professionalTimeline4 from '../assets/images/professionalTimeline4.jpg';
// import professionalTimeline5 from '../assets/images/logo.svg';
import ProfessionalTimeline5 from '../assets/images/professional-ubus';

export default function Home() {
    return (
        <Main>
            <div className="professional">
                <figure>
                    <div className="image-text">
                        <span>PROFESSIONAL</span>
                        <span>and</span>
                        <span>TIMELINE</span>
                    </div>
                </figure>
                <div className="title">
                    <h2>Professional and Timeline</h2>
                </div>

                <div className="history">
                    <div className="image box">
                        <img
                            src={professionalTimeline4}
                            alt="Thomas Greg &amp; Sons"
                        />
                    </div>
                    <div className="left">
                        <div className="dates">
                            <div className="date">2022</div>
                            <div className="date">&#128522;</div>
                        </div>
                        <div className="line" />
                        <div className="post">
                            <span>
                                Coordinator / Architect / Developer / Designer
                            </span>
                        </div>
                    </div>
                </div>

                <div className="history right">
                    <div className="left">
                        <div className="dates">
                            <div className="date">2019</div>
                            <div className="date">2022</div>
                        </div>
                        <div className="line" />
                        <div className="post">
                            <span>
                                Coordinator / Architect / Developer / Designer
                            </span>
                        </div>
                    </div>
                    <div className="image">
                        {/* <img
                            // src={professionalTimeline5}
                            alt="UBus Tecnologia"
                            className="svg"
                        /> */}
                        <ProfessionalTimeline5 className="ubus" />
                    </div>
                </div>

                <div className="history">
                    <div className="image box">
                        <img
                            src={professionalTimeline4}
                            alt="Thomas Greg &amp; Sons"
                        />
                    </div>
                    <div className="left">
                        <div className="dates">
                            <div className="date">2011</div>
                            <div className="date">2019</div>
                        </div>
                        <div className="line" />
                        <div className="post">
                            <span>Analyst / Developer / Designer</span>
                        </div>
                    </div>
                </div>

                <div className="history right">
                    <div className="left">
                        <div className="dates">
                            <div className="date">2007</div>
                            <div className="date">2011</div>
                        </div>
                        <div className="line" />
                        <div className="post">
                            <span>Project Manager / Webmaster</span>
                        </div>
                    </div>
                    <div className="image box">
                        <img src={professionalTimeline1} alt="IBM" />
                    </div>
                </div>

                <div className="history">
                    <div className="image box">
                        <img src={professionalTimeline3} alt="Newtrend" />
                    </div>
                    <div className="left">
                        <div className="dates">
                            <div className="date">2007</div>
                        </div>
                        <div className="line" />
                        <div className="post">
                            <span>Developer / Designer</span>
                        </div>
                    </div>
                </div>

                <div className="history right">
                    <div className="left">
                        <div className="dates">
                            <div className="date">2005</div>
                            <div className="date">2006</div>
                        </div>
                        <div className="line" />
                        <div className="post">
                            <span>Trainee / System automator</span>
                        </div>
                    </div>
                    <div className="image box">
                        <img
                            src={professionalTimeline2}
                            alt="Caixa Econômica Federal"
                        />
                    </div>
                </div>
            </div>
        </Main>
    );
}
