import * as React from "react"

function SvgComponent(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 37.74 47.169"
      height={178.275}
      width={142.641}
      {...props}
    >
      <path
        d="M12.372 20.709c0-.2-.568-15.302-.434-15.302.133 0 5.98.435 5.98.435l-.267 15z"
        fill="#72cee4"
      />
      <path
        d="M6.488 46.44L6.11 34.841s-.189-1.04-.425-1.252c-.236-.213-1.89-1.984-1.89-1.984S1.67 27.612 1.598 27.47c-.07-.142-1.11-4.678-1.11-4.678s.118-2.032.378-2.008c.26.024 30.71.165 30.71.165l.119 2.552 5.15 5.74.236 2.056-1.984.85-3.284.189-.425 6.709-1.276 1.276-9.45.378-.425 5.953-13.607.142z"
        fill="#fdcd9f"
      />
      <path
        d="M17.639 20.194c.283-.614.236-3.78.236-3.78L27.372.777l6.898 3.827-5.245 10.064-3.685 6.189z"
        fill="#fdda3e"
      />
      <path
        d="M11.851 20.738l-6.142-.142s-2.055-3.402-2.126-3.567c-.071-.165-.874-2.339-.874-2.764 0-.425.826-3.213.826-3.213l3.379-.307 2.504 1.441s1.866 2.906 2.008 3.142c.141.236.52 4.252.52 4.252z"
        fill="#f37eb2"
      />
      <path d="M14.482.014c-3.847.368-2.824 5.122-3.02 7.79-.355 1.856.772 9.19-1.33 4.598-1.955-2.064-3.6-2.291-3.6-2.291s.017-.314-.034-.702c.404-3.26-3.455-4.67-5.788-5.92C-.61 3.284.37 5.965.102 6.621-.07 8.785.62 10.615 2.52 11.735c-.353 1.326-.024 4.626 1.47 6.272.682.945.637 2.819-1.214 2.416-1.09 0-3.104-.373-2.572 1.453-.273 4.776 2.318 9.22 5.437 12.672.526 4.138.014 8.357.35 12.52 1.069-.16 3.564.584 3.752-.536-1.643-.088-2.83.18-2.973-.915-.311-2.404.143-7.137-.084-9.85-.084-.864-.285-1.681-.701-2.416-3.252-3.115-5.328-7.567-4.883-12.128h30.162c-1.094 4.312 4.567 5.666 5.58 9.192-.796 2.656-5.844-.638-5.558 2.806-.31 2.16 1.065 6.516-2.274 6.836-2.937.425-6.006-.47-8.673.371-.81 1.683.55 6.181-1.51 5.932-2.876.437.519 1.076 1.617.69.566-2.596-.716-6.676 3.287-6.195 2.73-.154 7.835 1.128 8.28-2.803.19-2.688-1.025-6.504 3.014-5.57 2.331.342 3.8-2.75 1.714-4.14-1.942-2.414-5.686-3.919-4.784-7.58-1.188-.954-4.624.684-5.367-1.242 2.468-5.097 6.01-9.958 8.178-15.07-2.38-1.539-5.015-3.45-7.69-4.277-3.297 4.772-5.874 10.013-8.865 14.982-.51-4.609.56-9.616-.959-13.973A3.125 3.125 0 0014.482.014zm13.334 1.391c2.032 1.006 4.213 1.988 5.912 3.504-.693 1.703-1.951 4.785-4.24 2.896-1.972.289 1.974 1.355 1.148 2.495-.882 1.418-1.483 4.515-3.426 2.617-1.361-1.133-1.574.786-.152.99 1.988 1.365-1 3.571-1.335 5.119-1.144 2.088-6.373 1.276-7.688.61-.187-4.265 3.484-7.508 5.037-11.215 1.515-2.332 2.63-5.008 4.476-7.09zM17.049 6.279c.036 4.186.216 14.006.216 14.006l-4.81.042.023-14.061c1.505-.071 3.02-.198 4.571.013zM6.533 11.175c3.287.355 4.384 4.395 4.886 7.142.95 2.968-2.56 1.6-4.295 1.797-2.15-.414-3.051-3.13-3.85-4.94-.412-2.006 1.152-4.095 3.259-4zm7.232 35.289c-1.392.812 1.23.791 0 0z" />
      <path
        d="M1.464 5.09s3.528 1.7 3.725 2.389c.197.689.955 3.952-2.906 2.706-1.641-.53-.819-5.095-.819-5.095zM12.772 5.146h4.016V2.595c-.927-2.06-3.462-1.226-3.945 0z"
        fill="#546a75"
      />
    </svg>
  )
}

export default SvgComponent
