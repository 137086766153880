import { darken, lighten, shade } from 'polished';

const primary = '#444A8B'; // '#d3d3d3';
const secundary = '#ec7d48';
const tertiary = '#BCBEC0';
const quaternary = '#414042';

export default {
    title: 'dark',

    global: {
        primary,
        secundary,
        tertiary,
        quaternary,

        // background: '#292929',
        background: '#1E1F23',
        text: '#d6d6d6',
        highlightText: '#d3d3d3',
        subtext: '#8c8c8c',
    },

    font: {
        thin: 100,
        light: 300,
        regular: 400,
        medium: 500,
        bold: 700,
        black: 900,
    },

    link: {
        color: '#d3d3d3',
    },

    input: {
        color: '#f4ede8',
        placeholder: '#666360',

        container: {
            background: '#26242b',
            color: '#666360',
            border: '#232129',
        },

        isFocused: {
            color: '#ff9000',
            border: '#ff9000',
        },

        isFilled: {
            color: '#ff9000',
        },

        error: {
            background: '#c53030',
            color: '#fff',
            border: '#c53030',
        },
    },

    button: {
        default: {
            background: primary,
            backgroundHover: shade(0.2, primary),
            color: '#fff',
        },

        transparent: {
            background: 'rgba(255,255,255,0.1)',
            backgroundHover: darken(0.2, 'rgba(255,255,255,0.1)'),
            color: '#fff',
        },
    },

    switcher: {
        background: '#777777',
        color: '#fff',

        checked: {
            background: '#2d377d',
            color: '#fff',
        },

        circle: {
            background: '#fff',
            icon: '#FFDA2D',
            border: '1px solid transparent',
        },
    },

    themeSwitcher: {
        background: '#777777',

        circle: {
            background: '#515151',
            icon: '#FFDA2D',
            border: '1px solid transparent',
        },
    },

    signin: {
        gear: {
            color: '#363636',
            opacity: 1,
        },
    },

    modal: {
        background: '#262626',
        close: {
            color: 'rgba(255, 255, 255, 0.6)',

            hover: {
                background: 'rgba(255, 255, 255, 0.04)',
                color: 'rgba(255, 255, 255, 0.2)',
            },
        },
    },

    main: {
        header: {
            // background: '#28262e',
            background: '#1E1F23',
            border: '#313236',
        },
    },

    sidebar: {
        background: '#151718',
    },

    table: {
        background: '#1E1F23',
        color: '#2f323a',

        button: {
            color: '#adadad',
        },
    },

    navBar: {
        backgroundItem: '#484a4d',
        backgroundSubitem: '#242526',
        border: '#313236',
        hover: '#363638',
    },

    filterRow: {
        border: '#313236',

        button: {
            background: '#bcbec0',
            color: '#46474a',
            border: '#46474a',

            hover: {
                background: lighten(0.1, '#bcbec0'),
                color: lighten(0.2, '#46474a'),
            },
        },
    },
};
