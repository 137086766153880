/* eslint-disable array-callback-return */
/* eslint-disable consistent-return */
import Main from 'pages/Main';
import React from 'react';
import ReactWordcloud from 'react-wordcloud';
import Creativity from '../assets/images/skill-creativity';
import Focus from '../assets/images/skill-focus';
import Organize from '../assets/images/skill-organize';
import Adaptability from '../assets/images/skill-adaptability';
import Communication from '../assets/images/skill-communication';
import Leadership from '../assets/images/skill-leadership';

export default function Home() {
    const skills = [
        {
            title: 'Languages',
            color: '#4888AC',
            content: [
                {
                    text: 'Bootstrap',
                    value: 64,
                },
                {
                    text: 'C#',
                    value: 64,
                },
                {
                    text: 'C/C++',
                    value: 64,
                },
                {
                    text: 'Clipper',
                    value: 64,
                },
                {
                    text: 'Cobol',
                    value: 64,
                },
                {
                    text: 'CSS',
                    value: 64,
                },
                {
                    text: 'HTML',
                    value: 64,
                },
                {
                    text: 'Java',
                    value: 64,
                },
                {
                    text: 'Javascript',
                    value: 64,
                },
                {
                    text: 'Pascal',
                    value: 64,
                },
                {
                    text: 'PHP',
                    value: 64,
                },
                {
                    text: 'SASS/LESS',
                    value: 64,
                },
                {
                    text: 'Typescript',
                    value: 64,
                },
            ],
        },
        {
            title: 'UX/UI',
            color: '#719b60',
            content: [
                {
                    text: 'Nielsen Heuristics / Gestalt',
                    value: 64,
                },
                {
                    text: 'Postural / Structural / Behavioral',
                    value: 64,
                },
                {
                    text: 'Progressive Web',
                    value: 64,
                },
                {
                    text: 'Semiotic Engineering',
                    value: 64,
                },
                {
                    text: 'T-Shaped',
                    value: 64,
                },
            ],
        },
        {
            title: 'Frameworks',
            color: '#b7b7b7',
            content: [
                {
                    text: 'ADO.NET',
                    value: 64,
                },
                {
                    text: '.NET Standard',
                    value: 64,
                },
                {
                    text: 'Core',
                    value: 64,
                },
                {
                    text: 'Entity Framework',
                    value: 64,
                },
                {
                    text: 'NHibernate',
                    value: 64,
                },
                {
                    text: 'Node.js',
                    value: 64,
                },
                {
                    text: 'React JS',
                    value: 64,
                },
            ],
        },
        {
            title: 'Patterns',
            color: '#8469A4',
            content: [
                {
                    text: 'DDD/TDD/BDD',
                    value: 64,
                },
                {
                    text: 'Interoperability',
                    value: 64,
                },
                {
                    text: 'MVC',
                    value: 64,
                },
                {
                    text: 'MVVM',
                    value: 64,
                },
                {
                    text: 'MVP',
                    value: 64,
                },
                {
                    text: 'RESTfull API',
                    value: 64,
                },
                {
                    text: 'SaaS',
                    value: 64,
                },
                {
                    text: 'Singleton',
                    value: 64,
                },
                {
                    text: 'SOA',
                    value: 64,
                },
                {
                    text: 'Unit of Work',
                    value: 64,
                },
                {
                    text: 'Webform',
                    value: 64,
                },
            ],
        },
        {
            title: 'Databases',
            color: '#A74F7B',
            content: [
                {
                    text: 'Access',
                    value: 64,
                },
                {
                    text: 'DB3',
                    value: 64,
                },
                {
                    text: 'MongoDB',
                    value: 64,
                },
                {
                    text: 'MySQL',
                    value: 64,
                },
                {
                    text: 'Redis',
                    value: 64,
                },
                {
                    text: 'SQL',
                    value: 64,
                },
            ],
        },
        {
            title: 'Softwares',
            color: '#d49f55',
            content: [
                {
                    text: 'Adobe Suite',
                    value: 64,
                },
                {
                    text: 'Corel',
                    value: 64,
                },
                {
                    text: 'GIMP',
                    value: 64,
                },
                {
                    text: 'IBM Suite',
                    value: 64,
                },
                {
                    text: 'Inkscape',
                    value: 64,
                },
                {
                    text: 'Visual Studio',
                    value: 64,
                },
            ],
        },
        {
            title: 'Mobile',
            color: '#A47292',
            content: [
                {
                    text: 'Android Studio',
                    value: 64,
                },
                {
                    text: 'Cordova',
                    value: 64,
                },
                {
                    text: 'React Native',
                    value: 64,
                },
                {
                    text: 'Swift',
                    value: 64,
                },
                {
                    text: 'Xaramin',
                    value: 64,
                },
                {
                    text: 'Xcode',
                    value: 64,
                },
            ],
        },
        {
            title: 'Project management',
            color: '#704160',
            content: [
                {
                    text: 'Agile methodology',
                    value: 64,
                },
                {
                    text: 'Azure DevOps',
                    value: 64,
                },
                {
                    text: 'Jira',
                    value: 64,
                },
                {
                    text: 'Monday',
                    value: 64,
                },
                {
                    text: 'Trello',
                    value: 64,
                },
            ],
        },
        {
            title: 'Services',
            color: '#9BAEBC',
            content: [
                {
                    text: 'Azure',
                    value: 64,
                },
                {
                    text: 'AWS',
                    value: 64,
                },
                {
                    text: 'Firebase',
                    value: 64,
                },
            ],
        },
        {
            title: 'Version control',
            color: '#008371',
            content: [
                {
                    text: 'Git',
                    value: 64,
                },
                {
                    text: 'SourceSafe',
                    value: 64,
                },
                {
                    text: 'Subversion',
                    value: 64,
                },
                {
                    text: 'Team Foundation',
                    value: 64,
                },
            ],
        },
        {
            title: 'Third-Part',
            color: '#E6F4F1',
            content: [
                {
                    text: 'Laravel',
                    value: 64,
                },
                {
                    text: 'Joomla',
                    value: 64,
                },
                {
                    text: 'Rational',
                    value: 64,
                },
                {
                    text: 'Telerik',
                    value: 64,
                },
                {
                    text: 'Wordpress',
                    value: 64,
                },
            ],
        },
        // {
        //     title: 'UX',
        //     color: '#fff',
        //     content: [
        //         {
        //             text: 'Nielsen',
        //             value: 64,
        //         },
        //     ],
        // },
    ];

    const words: { text: string; value: number }[] = [];

    skills.map(value => {
        value.content.map(item => {
            words.push({ text: item.text, value: item.value });

            return false;
        });

        return false;
    });

    type Scale = 'linear' | 'log' | 'sqrt';
    const valueScale: Scale = 'linear';

    type MinMaxPair = [number, number];
    const valueFontSize: MinMaxPair = [16, 24];
    const valueAngle: MinMaxPair = [0, 0];

    type Spiral = 'archimedean' | 'rectangular';
    const valueSpiral: Spiral = 'archimedean';

    const options = {
        colors: ['#4888AC', '#b7b7b7'],
        enableTooltip: false,
        deterministic: false,
        fontFamily: 'verdana',
        fontSizes: valueFontSize,
        fontStyle: 'normal',
        fontWeight: 'normal',
        padding: 3,
        rotations: 6,
        rotationAngles: valueAngle,
        scale: valueScale,
        spiral: valueSpiral,
        transitionDuration: 1000,
    };

    const findDeep = (data: any[], activity: string): any => {
        return data.find(e => {
            if (e.text === activity) return e.text;
            if (e.content) return findDeep(e.content, activity);
        });
    };

    const callbacks = {
        getWordColor: (word: { text: string }) => {
            const palabra = findDeep(skills, word.text);
            return palabra.color;
        },
    };

    return (
        <Main>
            <div className="skills">
                <figure>
                    <div className="image-text">
                        <span>SKILLS</span>
                        <span>and</span>
                        <span>EXPERIENCES</span>
                    </div>
                </figure>
                <div className="title">
                    <h2>SKILLS</h2>
                </div>
                <div className="skill">
                    <div>
                        <div>
                            <Creativity />
                        </div>
                        <p>
                            <span>Creativity</span> to design any necessary
                            inovation for a process and always grant a whole
                            vision.
                        </p>
                    </div>
                    <div>
                        <div>
                            <Organize />
                        </div>
                        <p>
                            <span>Organize</span> not only coding or in a
                            project, but organisation on each second of our time
                            make things better to everyone.
                        </p>
                    </div>
                    <div>
                        <div>
                            <Leadership />
                        </div>
                        <p>
                            <span>Leadership</span> to know when, where and how
                            on a daily basis. Managing the flow of things to
                            make all a peaceful and predictable way.
                        </p>
                    </div>
                    <div>
                        <div>
                            <Communication />
                        </div>
                        <p>
                            <span>Communication</span> to take people closer and
                            friendly, in most cases, much more a listener than a
                            talkative.
                        </p>
                    </div>
                    <div>
                        <div>
                            <Adaptability />
                        </div>
                        <p>
                            <span>Adaptability</span> to take other way any time
                            and follow the world.
                        </p>
                    </div>
                    <div>
                        <div>
                            <Focus />
                        </div>
                        <p>
                            <span>Focus</span> to determine the best way to
                            handle and guide since a simple &quot;if&quot; to an
                            entire project.
                        </p>
                    </div>
                </div>
                <div className="title">
                    <h2>EXPERIENCES</h2>
                </div>
                <div className="experiences">
                    <div style={{ height: '100%', width: '100%' }}>
                        <ReactWordcloud
                            options={options}
                            words={words}
                            callbacks={callbacks}
                        />
                    </div>
                </div>
            </div>
        </Main>
    );
}
