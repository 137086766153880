import styled, { css } from 'styled-components';
import { BsMoon } from 'react-icons/bs';
import { FiSun } from 'react-icons/fi';
import { reactSvgComponentToMarkupString } from 'utils';

export const SwitchContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;

    .toggle-switch {
        position: relative;
        margin-right: 10px;
        width: 75px;
        display: inline-block;
        vertical-align: middle;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        text-align: left;
    }
    .toggle-switch-checkbox {
        display: none;
    }
    .toggle-switch-label {
        display: block;
        overflow: hidden;
        cursor: pointer;
        border: 0 solid #bbb;
        border-radius: 20px;
        margin: 0;
    }
    .toggle-switch-label:focus {
        outline: none;
    }
    .toggle-switch-label:focus > span {
        box-shadow: 0 0 2px 5px red;
    }
    .toggle-switch-label > span:focus {
        outline: none;
    }
    .toggle-switch-inner {
        display: block;
        width: 200%;
        margin-left: -100%;
        transition: margin 0.1s ease-in 0s;
    }
    .toggle-switch-inner:before,
    .toggle-switch-inner:after {
        display: block;
        float: left;
        width: 50%;
        height: 34px;
        padding: 0;
        line-height: 34px;
        font-size: 14px;
        color: white;
        font-weight: bold;
        box-sizing: border-box;
    }
    .toggle-switch-inner:before {
        content: attr(data-yes);
        text-transform: uppercase;
        padding-left: 10px;
        background-color: ${props => props.theme.switcher.checked.background};
        color: ${props => props.theme.switcher.checked.color};
    }
    .toggle-switch-disabled {
        background-color: #ddd;
        cursor: not-allowed;
    }
    .toggle-switch-disabled:before {
        background-color: #ddd;
        cursor: not-allowed;
    }
    .toggle-switch-inner:after {
        content: attr(data-no);
        text-transform: uppercase;
        padding-right: 10px;
        background-color: ${props => props.theme.switcher.background};
        color: ${props => props.theme.switcher.color};
        text-align: right;
    }
    .toggle-switch-switch {
        display: block;
        width: 24px;
        margin: 5px;
        background: ${props => props.theme.switcher.circle.background};
        position: absolute;
        top: 0;
        bottom: 0;
        right: 40px;
        border: 0 solid #bbb;
        border-radius: 20px;
        transition: all 0.1s ease-in 0s;

        &:hover {
            box-shadow: 0 0 1px 10px rgba(159, 168, 218, 0.04),
                0px 3px 1px -2px rgba(255, 255, 255, 0.2),
                0px 2px 2px 0px rgba(255, 255, 255, 0.14),
                0px 1px 5px 0px rgba(255, 255, 255, 0.12);
        }
    }
    .toggle-switch-checkbox:checked
        + .toggle-switch-label
        .toggle-switch-inner {
        margin-left: 0;
    }
    .toggle-switch-checkbox:checked
        + .toggle-switch-label
        .toggle-switch-switch {
        right: 0px;
    }
    .toggle-switch.small-switch {
        width: 40px;
    }
    .toggle-switch.small-switch .toggle-switch-inner:after,
    .toggle-switch.small-switch .toggle-switch-inner:before {
        content: '';
        height: 20px;
        line-height: 20px;
    }
    .toggle-switch.small-switch .toggle-switch-switch {
        width: 16px;
        right: 20px;
        margin: 2px;
    }
    @media screen and (max-width: 991px) {
        .toggle-switch {
            transform: scale(0.9);
        }
    }
    @media screen and (max-width: 767px) {
        .toggle-switch {
            transform: scale(0.825);
        }
    }
    @media screen and (max-width: 575px) {
        .toggle-switch {
            transform: scale(0.75);
        }
    }

    span {
        color: ${props => props.theme.global.text};
        font-size: 1rem;
    }

    .theme {
        .toggle-switch-switch {
            display: flex;
            justify-content: center;
            align-items: center;
            line-height: 0.5rem;
            width: 28px !important;
            height: 28px !important;
            border: ${props =>
                props.theme.themeSwitcher.circle.border} !important;
            top: -5px !important;

            ${props =>
                props.theme.title === 'dark'
                    ? css`
                          right: -10px !important;
                      `
                    : css`
                          /* top: -5px !important;
                          right: -10px !important; */
                      `}

            &:before {
                ${props =>
                    props.theme.title === 'dark'
                        ? css`
                              content: url(${reactSvgComponentToMarkupString(
                                  BsMoon,
                                  {
                                      color: props.theme.themeSwitcher.circle
                                          .icon,
                                  },
                              )});
                          `
                        : css`
                              content: url(${reactSvgComponentToMarkupString(
                                  FiSun,
                                  {
                                      color: props.theme.themeSwitcher.circle
                                          .icon,
                                  },
                              )});
                          `}
            }
        }
    }

    /*

    & .toggle-switch {
        position: relative;
        margin-right: 10px;
        width: 75px;
        display: inline-block;
        vertical-align: middle;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        text-align: left;

        &-checkbox {
            display: none;
        }

        &-label {
            display: block;
            overflow: hidden;
            cursor: pointer;
            border: 0 solid #bbb;
            border-radius: 20px;
            margin: 0;

            &:focus {
                outline: none;
                > span {
                    box-shadow: 0 0 2px 5px #ff0;
                }
            }
            > span:focus {
                outline: none;
            }
        }

        &-inner {
            display: block;
            width: 200%;
            margin-left: -100%;
            transition: margin 0.3s ease-in 0s;

            &::before,
            &::after {
                display: block;
                float: left;
                width: 50%;
                height: 34px;
                padding: 0;
                line-height: 34px;
                font-size: 14px;
                color: #fff;
                font-weight: bold;
                box-sizing: border-box;
            }

            &::before {
                content: attr(data-yes);
                text-transform: uppercase;
                padding-left: 10px;
                background-color: #2f855a;
                color: #fff;
            }
        }

        &-disabled {
            background-color: #ddd;
            cursor: not-allowed;
            &:before {
                background-color: #ddd;
                cursor: not-allowed;
            }
        }

        &-inner:after {
            content: attr(data-no);
            text-transform: uppercase;
            padding-right: 10px;
            background-color: #bbb;
            color: #fff;
            text-align: right;
        }

        &-switch {
            display: block;
            width: 24px;
            margin: 5px;
            background: #fff;
            position: absolute;
            top: 0;
            bottom: 0;
            right: 40px;
            border: 0 solid #bbb;
            border-radius: 20px;
            transition: all 0.3s ease-in 0s;
        }

        &-checkbox:checked + &-label {
            .toggle-switch-inner {
                margin-left: 0;
            }
            .toggle-switch-switch {
                right: 0px;
            }
        }

        &.small-switch {
            width: 40px;
            .toggle-switch-inner {
                &:after,
                &:before {
                    content: '';
                    height: 20px;
                    line-height: 20px;
                }
            }
            .toggle-switch-switch {
                width: 16px;
                right: 20px;
                margin: 2px;
            }
        }

        @media screen and (max-width: 991px) {
            transform: scale(0.9);
        }
        @media screen and (max-width: 767px) {
            transform: scale(0.825);
        }
        @media screen and (max-width: 575px) {
            transform: scale(0.75);
        }
    }
    */
`;

/*
export const ThemeSwitch = styled(InputSwitch)`
    .p-inputswitch-slider {
        cursor: pointer;
        z-index: 1;
        background: ${props => props.theme.themeSwitcher.background} !important;
    }
    .p-inputswitch-slider::before {
        display: flex;
        justify-content: center;
        align-items: center;
        line-height: 1em;
        border: ${props => props.theme.themeSwitcher.circle.border} !important;
        background: ${props =>
            props.theme.themeSwitcher.circle.background} !important;

        ${props =>
            props.theme.title === 'dark'
                ? css`
                      content: url(${reactSvgComponentToMarkupString(BsMoon, {
                          color: props.theme.themeSwitcher.circle.icon,
                      })});
                  `
                : css`
                      content: url(${reactSvgComponentToMarkupString(FiSun, {
                          color: props.theme.themeSwitcher.circle.icon,
                      })});
                  `}
    }
`;
*/
