import React, { useCallback, useState } from 'react';
import { useCookies } from 'react-cookie';
import { useTheme } from 'hooks/theme';
import { google } from 'utils/google';

const Lamp: React.FC = () => {
    const [cookies, setCookie] = useCookies(['lamp']);
    const { changeTheme } = useTheme();

    function handleLampState() {
        return cookies.lamp === 'true';
    }

    const handleTheme = useCallback(
        (type: boolean) => {
            changeTheme(type);
        },
        [changeTheme],
    );

    const [lamp, setLamp] = useState(handleLampState());

    function btnLamp() {
        const lampState = !lamp;
        setLamp(lampState);
        handleTheme(lampState);
        const today = new Date();
        setCookie('lamp', lampState, {
            path: '/',
            expires: new Date(today.setFullYear(today.getFullYear() + 1)),
        });

        google('Internal', 'Theme', lampState ? 1 : 2);
    }

    return (
        <svg
            className="lamp"
            viewBox="0 0 122.29 156.69"
            xmlns="http://www.w3.org/2000/svg"
            onClick={btnLamp}
        >
            <defs>
                <linearGradient id="grad1" x1="0%" y1="0%" x2="0%" y2="100%">
                    <stop offset="0%" stopColor="#ffe000" stopOpacity="0.5" />
                    <stop offset="100%" stopColor="#ffe000" stopOpacity="0" />
                </linearGradient>
            </defs>
            <path
                className={`lamp-light lamp-light-${lamp ? '' : 'off'}`}
                d="m40.763 95.551-40.763 61.144h122.29l-40.763-61.144z"
                fill="url(#grad1)"
            />
            <g>
                <circle
                    className={`bulb bulb-${lamp ? 'on' : 'off'}`}
                    cx="60"
                    cy="109.3"
                    r="10.7"
                />
                <line
                    className="style1"
                    x1="58.1"
                    x2="57.4"
                    y1="108.1"
                    y2="113.4"
                />
                <line
                    className="style2"
                    x1="62"
                    x2="62.6"
                    y1="108.1"
                    y2="113.4"
                />
                <polyline
                    className="style3"
                    points="57.8 113.5 58.3 112.8 58.8 113.5 59.6 112.8 60 113.5 60.7 112.9 61.2 113.5 61.8 112.8 62.3 113.5"
                />
            </g>
            <rect
                className="style4"
                x="51.526"
                y="66.7"
                width="18.6"
                height="15.6"
            />
            <rect className="style4" x="59.326" width="3" height="66.7" />
            <path
                className="style4"
                d="m60.826 80.3c-16.6 0-30 13.4-30 30h60c0-16.5-13.4-30-30-30z"
            />
        </svg>
    );
};

export default Lamp;
