import React, {
    useCallback,
    useContext,
    createContext,
    // PropsWithChildren,
} from 'react';
import { ThemeProvider } from 'styled-components';
import usePeristedState from '../utils/usePersistedState';
import light from '../styles/themes/light';
import dark from '../styles/themes/dark';

export interface Theme {
    title: string;
}

interface ThemeContextData {
    theme: string;
    toggleTheme(): void;
    changeTheme(type: boolean): void;
    changeThemeByTitle(theme: string): void;
    getTheme(): boolean;
}

interface Props {
    children: React.ReactNode;
}

const ThemeContext = createContext<ThemeContextData>({} as ThemeContextData);

export const ThemeTypeProvider: React.FC<Props> = ({ children }) => {
    // export const ThemeTypeProvider: React.FC = ({ children }) => {
    const [theme, setTheme] = usePeristedState<'dark' | 'light'>(
        'theme',
        'dark',
    );

    const toggleTheme = useCallback(() => {
        setTheme(theme === 'light' ? 'dark' : 'light');
    }, [setTheme, theme]);

    const changeTheme = useCallback(
        (type: boolean) => {
            setTheme(type ? 'light' : 'dark');
        },
        [setTheme],
    );

    const changeThemeByTitle = useCallback(
        (themeTitle: string) => {
            setTheme(themeTitle === 'light' ? 'light' : 'dark');
        },
        [setTheme],
    );

    const getTheme = useCallback(() => {
        return !(theme === 'light');
    }, [theme]);

    return (
        <ThemeContext.Provider
            value={{
                theme,
                toggleTheme,
                changeTheme,
                changeThemeByTitle,
                getTheme,
            }}
        >
            <ThemeProvider theme={theme === 'light' ? light : dark}>
                {children}
            </ThemeProvider>
        </ThemeContext.Provider>
    );
};

export function useTheme(): ThemeContextData {
    const context = useContext(ThemeContext);

    if (!context)
        throw new Error('useTheme must be used within an ThemeTypeProvider');

    return context;
}
